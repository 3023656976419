.dropdown-toggle::after {
    display: none !important;
}

.chat-users {
    z-index: 999;
    min-width: 250px;
    max-height: 430px;
    border-radius: 10px;
    font-size: 13px;
    position: fixed;
    bottom: 10px;
    right: -205px;
    transition: all 0.2s ease-in-out;
}

.chat-users.open {
    right: -5px;
}

.table tbody tr td {
    vertical-align: middle;
}

.bg-helpdex {
    background-color: #7239ea !important;
}

.text-helpdex {
    color: #7239ea !important;
}

.chat-avatar-status {
    position: absolute;
    bottom: -3px;
    right: 0;
    border: 1px solid #fff;
    border-radius: 50%;
}

.chat-user-row:hover .private-chat {
    display: block;
}

.chat-user-row .private-chat {
    display: none;
}

.chat {
    position: fixed;
    bottom: 0;
    right: 35px;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    z-index: 999;
    max-width: 80vh;
    overflow-x: hidden;

}

.chat-window {
    width: 250px;
    margin-right: 5px;
    height: 400px;
}

.chat-windows {
    min-width: 200px;
    max-height: 400px;
    margin-right: 10px;
    justify-content: center;
    display: flex;
}

.chat-message {
    max-width: 100%;
}

.typing-animation {
    width: 100%;
    height: 15px;
    display: flex;
    align-items: center;
}

.typing-animation .dot {
    width: 10px;
    height: 10px;
    background-color: #7239ea;
    border-radius: 50%;
    display: inline-block;
    animation: typing 1.4s infinite ease-in-out;
    opacity: .1;
}

.typing-animation .dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-animation .dot:nth-child(3) {
    animation-delay: 0.3s;
}

@keyframes typing {
    0% {
        transform: translateY(0);
        opacity: .1;
    }

    12% {
        opacity: .2;
    }

    25% {
        opacity: .4;
    }

    37% {
        opacity: .5;
    }

    50% {
        transform: translateY(-5px);
        opacity: .6;
    }

    62% {
        opacity: .7;
    }

    75% {
        opacity: .6;
    }

    87% {
        opacity: .4;
    }

    100% {
        transform: translateY(0);
        opacity: .2;
    }
}

/* dark mode 
--bs-body-color variable
*/
[data-bs-theme=dark] {
    --bs-body-color: #92929f !important;
}